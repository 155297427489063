/**
 * Created by adrianp on 30.06.15.
 */
document.addEventListener('DOMContentLoaded', function () {
    console.log('header :: init');
    let $header = <HTMLElement> document.querySelector('body > .siteHeader');
    if (!$header) {
        console.log('header :: init => no header found');
        return;
    }


    let distance = 0;
    let $distanceElement = <HTMLElement> $header.querySelector('.upper');
    function distanceSet() {
        distance = $distanceElement.clientHeight;
    }

    // window.addEventListener('scroll', distanceSet, false);
    window.addEventListener('resize', distanceSet, false);
    distanceSet();

    let flyingFlag = false;
    let $flyingElement = <HTMLElement> $header.querySelector('.lower');
    function updateFlyingState () {
        if (window.scrollY > distance && !flyingFlag) {
            $flyingElement.classList.add('flying');
            flyingFlag = true;
        } else if (window.scrollY <= distance && flyingFlag) {
            $flyingElement.classList.remove('flying');
            flyingFlag = false;
        }
    }
    window.addEventListener('scroll', updateFlyingState, false);
    window.addEventListener('resize', updateFlyingState, false);


    // let currentPadding = 0;
    // let runner = () => {
    //     let height = $header.clientHeight;
    //     if (height !== currentPadding) {
    //         console.log('header :: runner => updating BODY padding value');
    //         currentPadding = height;
    //         document.body.style.paddingTop = currentPadding + 'px';
    //     }
    //     requestAnimationFrame(runner);
    // };
    //
    // requestAnimationFrame(runner);

}, false);
